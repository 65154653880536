import React from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { Launch } from '@material-ui/icons';
import { MENTORSHIP_RESOURCE_LINK, PATH } from '../../constants';
import ManageBookings from './ManageBookings';
import OpenBookings from './OpenBookings';
import DetailedReport from './DetailedReport';
import InterviewSimulator from './interviewSimulator/InterviewSimulator';
import SessionFeedback from './interviewSimulator/postInterview/SessionFeedback';
import WatchRecording from './WatchRecording';
import NavigationBar from '../../components/header/NavigationBar';
import polarisLogo from '../../static/img/polaris-logo.png';
import InterviewRoster from '../../components/interview_roster/InterviewRoster';

export default function MentorshipRouter(props) {
  return (
    <>
      <NavigationBar
        navLogo={polarisLogo}
        navLogoClass='object-cover h-9 md:h-12'
        menubar={
          <div className='flex items-center gap-10 w-11/12'>
            <NavLink
              className='text-black text-opacity-50 py-3 border-b-4 border-solid border-transparent rounded'
              activeClassName='font-medium border-ab-secondary text-ab-secondary text-opacity-100'
              exact
              to={PATH.MENTORSHIP.MANAGE_BOOKINGS}
            >
              My Bookings
            </NavLink>
            <NavLink
              className='text-black text-opacity-50 py-3 border-b-4 border-solid border-transparent rounded'
              activeClassName='font-medium border-ab-secondary text-ab-secondary text-opacity-100'
              exact
              to={PATH.MENTORSHIP.OPEN_BOOKINGS}
            >
              Open Bookings
            </NavLink>
            <NavLink
              className='text-black text-opacity-50 py-3 border-b-4 border-solid border-transparent rounded'
              activeClassName='font-medium border-ab-secondary text-ab-secondary text-opacity-100'
              exact
              to={PATH.MENTORSHIP.LEGACY}
            >
              Previous Bookings
            </NavLink>
            <a
              href={MENTORSHIP_RESOURCE_LINK}
              target='_blank'
              rel='noreferrer'
              className='flex items-center gap-1 bg-blue-c3 bg-opacity-20 text-blue-c2 rounded px-2 py-1 ml-auto'
            >
              Guidelines
              <Launch />
            </a>
          </div>
        }
      />

      <Switch>
        <Route path={PATH.MENTORSHIP.MANAGE_BOOKINGS} component={ManageBookings} exact />
        <Route path={PATH.MENTORSHIP.OPEN_BOOKINGS} component={OpenBookings} />
        <Route path={`${PATH.MENTORSHIP.DETAILED_REPORT}/:bookingId`} component={DetailedReport} />
        <Route path={`${PATH.MENTORSHIP.WATCH_RECORDING}/:bookingId`} component={WatchRecording} />
        <Route
          path={[PATH.MENTORSHIP.CONDUCT_SESSION, PATH.MENTORSHIP.PREVIEW_SESSION]}
          component={InterviewSimulator}
        />
        <Route path={PATH.MENTORSHIP.POST_INTERVIEW} component={SessionFeedback} />
        <Route path={PATH.MENTORSHIP.LEGACY}>
          <InterviewRoster compactMode />
        </Route>
        {/* <Route exact to={PATH.MENTORSHIP.HOME} component={CoachHome} /> */}
        <Redirect from={PATH.MENTORSHIP.HOME} to={PATH.MENTORSHIP.MANAGE_BOOKINGS} />
      </Switch>
    </>
  );
}
