/* eslint-disable react/jsx-filename-extension */
import { useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

export const useHideFreshDesk = () => {
  useLayoutEffect(() => {
    window?.FreshworksWidget && window.FreshworksWidget('hide', 'launcher');
    return () => {
      window?.FreshworksWidget && window.FreshworksWidget('show', 'launcher');
    };
  }, [window?.FreshworksWidget]);
};

export default function useFreshdesk() {
  const userDetails = useSelector((state) => state.authReducer.loggedInUser);

  function loadScript(src) {
    return new Promise((resolve, reject) => {
      const scriptSetting = document.createElement('script');
      scriptSetting.innerText = `
      window.fwSettings = {
        widget_id: 89000000471,
      };
      !(function () {
        if ('function' != typeof window.FreshworksWidget) {
          var n = function () {
            n.q.push(arguments);
          };
          (n.q = []), (window.FreshworksWidget = n);
        }
      })()
      `;

      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.defer = true;

      script.onload = (e) => {
        resolve(true);
      };

      script.onerror = (e) => {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('Failed Loading FreshDesk Plugin');
      };
      document.body.appendChild(scriptSetting);
      document.body.appendChild(script);
    });
  }

  const loadFreshDesk = async () => {
    const freshDesk = await loadScript('https://ind-widget.freshworks.com/widgets/89000000471.js');

    if (freshDesk) {
      window.FreshworksWidget('identify', 'ticketForm', {
        name: `${userDetails?.firstName} ${userDetails?.lastName}`,
        email: userDetails?.email,
      });
      window.FreshworksWidget('prefill', 'ticketForm', {
        custom_fields: {
          cf_mobile_number: userDetails?.phone?.replace('+91', ''),
        },
      });
    }
  };

  useEffect(() => {
    if (!window.FreshworksWidget && Object.keys(userDetails || {}).length) {
      loadFreshDesk();
    }
  }, [userDetails]);
}

export const CoachFreshDeskWidget = ({ style, className }) => {
  useHideFreshDesk();
  const defaultStyle = {
    // backgroundColor: 'rgb(19, 204, 19)',
    zIndex: 1111,
    // color: 'rgb(255, 255, 255)',
    borderRadius: '30px 8px 30px 30px',
    ...style,
  };

  return (
    <section onClick={() => window?.FreshworksWidget('open')} id='MyFreshDeskWidget'>
      <div
        className={`${className}  cursor-pointer  w-auto whitespace-nowrap flex items-center justify-center text-gray-400 font-medium`}
        style={defaultStyle}
      >
        <HelpOutlineIcon className='mr-1' style={{ fontSize: 18 }} />
        <p className='  whitespace-nowrap select-none overflow-hidden  '>Need help?</p>
      </div>
    </section>
  );
};
