import React, { useEffect, useState } from 'react';
import { AcademicCap, Calendar, StarRatings, User, ChevronUp, ChevronDown } from './Icons';
import { getMentorshipSchedule } from '../../services/existingServices';
import { useSelector } from 'react-redux';
import { loggedInUserSelector } from '../../redux/authSlice';
import { Info, Launch } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import { PATH } from '../../constants';
import NavigationBar from '../header/NavigationBar';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
dayjs.extend(weekday);

const TAB = {
  ALL: 'All',
  CUR: 'Current Week',
  UPC: 'Upcoming Week',
};

const activeTabClass = 'w-full text-center p-2 border-b-2 border-solid border-ab-secondary';
const inactiveTabClass = 'w-full text-center p-2 cursor-pointer';

const menuBarComponent = (
  <div className='flex items-center w-11/12'>
    <NavLink
      className='text-black text-opacity-50 py-3 border-b-4 border-solid border-transparent rounded'
      activeClassName='font-medium border-ab-secondary text-ab-secondary text-opacity-100'
      exact
      to={PATH.INTERVIEW_ROSTER}
    >
      My Sessions
    </NavLink>
    <a
      href='https://www.notion.so/almabetter/Published-for-Mentors-a75b3bdb4f7d489384341c67b62bb460'
      target='_blank'
      rel='noreferrer'
      className='flex items-center gap-1 bg-blue-c3 bg-opacity-20 text-blue-c2 rounded px-2 py-1 ml-auto'
    >
      Resources
      <Launch />
    </a>
  </div>
);

function InterviewRoster({ compactMode }) {
  const [currentTab, setCurrentTab] = useState(TAB.ALL);
  const [sessionStatus, setSessionStatus] = useState('');
  const [sessions, setSessions] = useState(null);
  const [popup, setPopup] = useState(null);

  const user = useSelector(loggedInUserSelector);

  useEffect(() => {
    const loadSessions = async () => {
      const { items } = await getMentorshipSchedule(user.email);
      setSessions(items);
    };
    loadSessions();
  }, []);

  // filter by sessionStatus
  let filterSessions = sessions;
  if (sessionStatus)
    filterSessions = filterSessions.filter(({ status }) => status === sessionStatus);

  // filter by currentTab
  if (currentTab === TAB.CUR) {
    const thisMonday = dayjs()
      .subtract(dayjs().weekday() - 1, 'day')
      .startOf('day');

    const thisSunday = dayjs()
      .add(dayjs().weekday() - 1, 'day')
      .endOf('day');

    filterSessions = filterSessions.filter(({ deadline }) => {
      deadline = dayjs(deadline);
      return deadline >= thisMonday && deadline <= thisSunday;
    });
  }
  if (currentTab === TAB.UPC) {
    const nextMonday = dayjs().add(dayjs().weekday(), 'day').startOf('day');
    const nextSunday = dayjs()
      .add(dayjs().weekday() + 6, 'day')
      .endOf('day');
    filterSessions = filterSessions.filter(({ deadline }) => {
      deadline = dayjs(deadline);
      return deadline >= nextMonday && deadline <= nextSunday;
    });
  }

  return (
    <>
      {!compactMode && <NavigationBar menubar={menuBarComponent} />}
      <div className='min-h-screen m-auto text-gray-600 py-8 px-4 bg-gray-100'>
        {/* info message */}
        <div className='bg-yellow-c1 bg-opacity-10 text-yellow-c1 border border-solid border-yellow-c1 px-4 py-1 max-w-min mx-auto whitespace-nowrap rounded-md shadow flex items-center gap-2 mb-4'>
          <Info color='inherit' />
          <p>
            These are the sessions done on legacy mentorship platform, new sessions will be
            available in {compactMode ? '"My Bookings"' : '"Mentorship (New)"'}
          </p>
        </div>

        {/* header */}
        <h1 className='bg-red-100 max-w-5xl mx-auto p-4 text-center text-2xl text-gray-700 font-semibold rounded shadow mb-8'>
          Mentorship cum AlmaX Interview Roster
        </h1>

        {/* tab selector */}
        {!compactMode && (
          <div className='max-w-xl mx-auto uppercase bg-white flex justify-around gap-4 my-8 p-2 border border-solid border-gray-300 rounded'>
            {Object.values(TAB).map((tab, i) => (
              <span
                onClick={() => setCurrentTab(tab)}
                className={currentTab === tab ? activeTabClass : inactiveTabClass}
                key={i}
              >
                {tab}
              </span>
            ))}
          </div>
        )}

        {/* sessionStatus filter */}
        {!compactMode && (
          <div className='text-center my-8 pr-2 max-w-min bg-white border border-solid border-gray-300 rounded-md overflow-hidden mx-auto'>
            <select
              onChange={({ target }) => setSessionStatus(target.value)}
              className='py-2 px-4 rounded cursor-pointer focus:outline-none'
              value={sessionStatus}
            >
              <option value='' className='italic'>
                Status
              </option>
              <option>LIVE</option>
              <option>DONE</option>
            </select>
          </div>
        )}

        {/* render sessions list */}
        <div className='max-w-5xl my-16 mx-auto text-lg'>
          {filterSessions ? (
            filterSessions.map((session) => (
              <div
                className='bg-white border border-solid border-gray-300 rounded-lg p-6 my-8 hover:shadow-lg'
                key={session.sessionId}
              >
                <div className='flex flex-col md:flex-row gap-4'>
                  {/* mentor details */}
                  <div className='w-full md:w-1/3 text-left'>
                    <div className='flex gap-2'>
                      <span className='font-semibold text-gray-600 text-xl mb-2'>
                        Coach Details
                      </span>
                      <AcademicCap iconStyle='w-7 h-7' />
                    </div>
                    <div>{session.coachName}</div>
                    <div>{session.coachEmail}</div>
                    <div>{session.coachPhone}</div>
                  </div>

                  {/* session details */}
                  <div className='w-full md:w-1/3 text-center'>
                    <div className='font-semibold text-gray-600 text-xl mb-2'>
                      {session.sessionName}
                    </div>
                    <div className='flex items-center justify-center gap-2 mb-6'>
                      <Calendar iconStyle='w-6 h-6 text-gray-500' />
                      <span>Deadline - {session.deadline}</span>
                    </div>
                    <div
                      className={
                        session.status === 'DONE' ? 'text-green-500 pb-6' : 'text-red-500 pb-6'
                      }
                    >
                      <span className='font-semibold border-2 border-solid border-current rounded py-2 px-4'>
                        STATUS - {session.status}
                      </span>
                    </div>
                    {session.status === 'LIVE' && (
                      <div className='pb-4'>
                        <button
                          onClick={() => setPopup(session.meetingLink)}
                          className='font-medium text-white bg-blue-500 hover:bg-blue-600 rounded py-2 px-4 focus:outline-none'
                        >
                          JOIN MEETING ROOM
                        </button>
                      </div>
                    )}
                  </div>

                  {/* mentee details */}
                  <div className='w-full md:w-1/3 text-right'>
                    <div className='flex gap-2 justify-end'>
                      <User iconStyle='w-7 h-7' />
                      <span className='font-semibold text-gray-600 text-xl mb-2'>
                        Candidate Details
                      </span>
                    </div>
                    <div>{session.candidateName}</div>
                    <div>{session.candidateEmail}</div>
                    <div>{session.candidatePhone}</div>
                    <div>{session.cohort}</div>
                  </div>
                </div>

                {/* Collapsible feedback card */}
                <Feedback
                  coachRatings={session.coachRatings}
                  coachRemarks={session.coachRemarks}
                  candidateRatings={session.candidateRatings}
                  questionsLink={session.questionsLink}
                  recordingLink={session.recordingLink}
                />
              </div>
            ))
          ) : (
            <div className='text-center'>Loading...</div>
          )}
        </div>

        {/* popup for showing meeting password */}
        {popup && (
          <div className='fixed h-screen inset-0'>
            <div className='flex items-center justify-center h-full bg-gray-500 bg-opacity-80'>
              <div className='border border-solid border-gray-300 rounded-lg bg-white p-8'>
                <div className='text-lg text-center'>
                  Meeting Room Password: <b>alma#coach#</b>
                </div>
                <div className='mt-8 flex gap-8'>
                  <button
                    onClick={() => setPopup(null)}
                    className='focus:outline-none border border-solid border-blue-500 rounded text-blue-500 py-2 px-4'
                  >
                    CANCEL
                  </button>
                  <a
                    href={popup}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='font-medium text-white bg-blue-500 hover:bg-blue-600 rounded py-2 px-4'
                  >
                    CONTINUE TO MEETING
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

const Feedback = ({
  coachRatings,
  coachRemarks,
  candidateRatings,
  questionsLink,
  recordingLink,
}) => {
  const [toggle, setToggle] = useState(false);

  return (
    <div className='mt-4 p-4 border border-solid border-gray-200 rounded-lg hover:border-gray-400'>
      <div className='flex flex-col md:flex-row'>
        {/* Toggle input */}
        <span
          onClick={() => setToggle(!toggle)}
          className='cursor-pointer text-center md:text-left flex'
        >
          <span>
            Coach Feedback & Ratings <span className='font-light mx-2'>|</span> Candidate Self
            Ratings
          </span>
          <span className='text-gray-500 text-right flex-grow md:ml-4'>
            {toggle ? (
              <ChevronUp iconStyle='inline w-6 h-6' />
            ) : (
              <ChevronDown iconStyle='inline w-6 h-6' />
            )}
          </span>
        </span>

        <span className='text-indigo-500 text-center md:text-right py-4 md:py-0 flex-grow'>
          <a href={questionsLink} target='_blank' rel='noopener noreferrer'>
            GO TO QUESTIONS
          </a>
        </span>
      </div>

      {toggle && (
        <>
          {coachRatings && candidateRatings ? (
            <div className='mt-8'>
              <div className='pb-2'>
                <div className='font-semibold text-xl border-b border-solid border-gray-200 mb-1 pb-1'>
                  Coach Feedback
                </div>
                <p className='text-justify whitespace-pre-line hover:bg-green-50'>{coachRemarks}</p>
              </div>

              <div className='mt-8 font-semibold text-xl flex flex-col md:flex-row justify-between border-b border-solid border-gray-200 pb-2 mb-2'>
                <span>Coach Ratings</span>
                <span className='text-center'>Session</span>
                <span className='text-right'>Candidate Ratings</span>
              </div>

              {Object.keys(coachRatings).map((ratingTitle, i) => (
                <div
                  className='flex flex-col md:flex-row justify-between py-4 hover:bg-yellow-50'
                  key={i}
                >
                  <span>
                    <StarRatings rating={coachRatings[ratingTitle]} />
                  </span>
                  <span className='text-center py-1 md:py-0'>{ratingTitle}</span>
                  <span className='ml-auto md:ml-0'>
                    <StarRatings rating={candidateRatings[ratingTitle]} />
                  </span>
                </div>
              ))}

              <div className='text-center mb-4 mt-8'>
                {recordingLink && recordingLink.includes('http') ? (
                  <a
                    href={recordingLink}
                    target='_blank'
                    rel='noreferrer'
                    className='bg-blue-500 hover:bg-blue-800 text-white rounded py-3 px-12'
                  >
                    WATCH RECORDING
                  </a>
                ) : (
                  <span className='bg-gray-400 text-white rounded py-3 px-12'>
                    NO RECORDING AVAILABLE
                  </span>
                )}
              </div>
            </div>
          ) : (
            <div className='mt-8'>
              This section will be populated after both coach and candidate submit the session form
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InterviewRoster;
