export const AcademicCap = ({ iconStyle }) => (
  <svg
    className={'inline ' + iconStyle}
    fill='currentColor'
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z' />
  </svg>
);

export const User = ({ iconStyle }) => (
  <svg
    className={'inline ' + iconStyle}
    fill='currentColor'
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      d='M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z'
      clipRule='evenodd'
    />
  </svg>
);

export const Calendar = ({ iconStyle }) => (
  <svg
    className={'inline ' + iconStyle}
    fill='currentColor'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M16.53 11.06L15.47 10l-4.88 4.88-2.12-2.12-1.06 1.06L10.59 17l5.94-5.94zM19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11z' />
  </svg>
);

export const ChevronUp = ({ iconStyle }) => (
  <svg
    className={'inline ' + iconStyle}
    fill='currentColor'
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      d='M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z'
      clipRule='evenodd'
    />
  </svg>
);

export const ChevronDown = ({ iconStyle }) => (
  <svg
    className={'inline ' + iconStyle}
    fill='currentColor'
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
      clipRule='evenodd'
    />
  </svg>
);

export const RatedStar = ({ iconStyle }) => (
  <svg className={iconStyle} viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.43 6L8.96 1.16c-.29-.95-1.63-.95-1.91 0L5.57 6H1.12C.15 6-.25 7.25.54 7.81l3.64 2.6-1.43 4.61c-.29.93.79 1.68 1.56 1.09L8 13.31l3.69 2.81c.77.59 1.85-.16 1.56-1.09l-1.43-4.61 3.64-2.6c.79-.57.39-1.81-.58-1.81h-4.45V6z'
      fill='#FDA623'
    />
  </svg>
);

export const UnratedStar = ({ iconStyle }) => (
  <svg className={iconStyle} viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.43 6L8.96 1.16c-.29-.95-1.63-.95-1.91 0L5.57 6H1.12C.15 6-.25 7.25.54 7.81l3.64 2.6-1.43 4.61c-.29.93.79 1.68 1.56 1.09L8 13.31l3.69 2.81c.77.59 1.85-.16 1.56-1.09l-1.43-4.61 3.64-2.6c.79-.57.39-1.81-.58-1.81h-4.45V6z'
      fill='#D5D5D5'
    />
  </svg>
);

export const StarRatings = ({ rating, size = 6 }) => (
  <span className='flex gap-2'>
    {[...Array(+5).keys()].map((i) => (
      <span key={i}>
        {i + 1 <= rating ? (
          <RatedStar iconStyle={`w-${size} h-${size}`} />
        ) : (
          <UnratedStar iconStyle={`w-${size} h-${size}`} />
        )}
      </span>
    ))}
  </span>
);
