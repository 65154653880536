import React, { useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import {
  ArrowBack,
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  Menu as MenuIcon,
} from '@material-ui/icons';

import almabetterLogo from '../../static/img/almabetterLogo.png';
import { COLOR_THEME, PATH, THINKIFIC_CONSTANTS } from '../../constants';
import RenderThumbnail from '../utils/RenderThumbnail';
import CommonButton from '../common/CommonButton';
import { Card, LogoutDoor, Person } from '../utils/Icons';
import { setColorTheme } from '../../redux/colorModeSlice';
import { CoachFreshDeskWidget } from '../../plugins/coachFreshdesk';

export default function NavigationBar({ navBarLinks, navLogo, navLogoClass, menubar }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [themeSwitch, setThemeSwitch] = useState(
    localStorage.getItem('color-theme') || COLOR_THEME.DARK
  );

  const history = useHistory();
  const dispatch = useDispatch();

  // Getting Signed-in user details
  const user = useSelector((state) => state.authReducer.loggedInUser);

  // expand/Collapse user menu (at top rightmost)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close User Menu (at top rightmost)
  const handleClose = () => {
    setAnchorEl(null);
  };

  // LogoutPage Handler
  const logout = async () => {
    history.push(PATH.AUTH.LOGOUT);
    handleClose();
  };

  const handleThemeSwitch = () => {
    // if set via local storage previously
    if (localStorage.getItem('color-theme')) {
      if (localStorage.getItem('color-theme') === COLOR_THEME.LIGHT) {
        setThemeSwitch(COLOR_THEME.DARK);
        document.documentElement.classList.add(COLOR_THEME.DARK);
        localStorage.setItem('color-theme', COLOR_THEME.DARK);
        dispatch(setColorTheme(COLOR_THEME.DARK));
      } else {
        document.documentElement.classList.remove(COLOR_THEME.DARK);
        localStorage.setItem('color-theme', COLOR_THEME.LIGHT);
        setThemeSwitch(COLOR_THEME.LIGHT);
        dispatch(setColorTheme(COLOR_THEME.LIGHT));
      }

      // if NOT set via local storage previously
    } else {
      if (document.documentElement.classList.contains(COLOR_THEME.DARK)) {
        document.documentElement.classList.remove(COLOR_THEME.DARK);
        localStorage.setItem('color-theme', COLOR_THEME.LIGHT);
        setThemeSwitch(COLOR_THEME.LIGHT);
      } else {
        document.documentElement.classList.add(COLOR_THEME.DARK);
        localStorage.setItem('color-theme', COLOR_THEME.DARK);
        setThemeSwitch(COLOR_THEME.DARK);
      }
    }
  };

  return (
    <>
      <div className='bg-ab-tertiary dark:bg-ab-primary w-screen h-auto py-4 shadow-md z-30 relative'>
        <div className='flex justify-between items-center min-w-min px-6 md:px-16'>
          <div className='flex gap-6 items-center'>
            {/* ---------------LOGO ----------- */}
            <NavLink to={PATH.HOME}>
              <img
                className={navLogoClass || 'object-cover h-5 md:h-6'}
                src={navLogo || almabetterLogo}
                alt='Almabetter Logo'
              />
            </NavLink>
            {/* <button
              disabled={true}
              onClick={(e) => setCourseMenu(e.currentTarget)}
              className={`bg-ab-tertiary dark:bg-ab-primary-shade-2 
                      border border-ab-primary border-opacity-20 dark:border-white 
                      rounded hidden md:flex items-center gap-2 pl-6 pr-4 py-3 
                      pointer-events-none`}
            >
              <span>{COURSE_TYPE_NAMES_MAPPING[COURSE_TYPE.DATA_SCIENCE]}</span>
              <ArrowDropDown />
            </button>
            <Menu
              anchorEl={courseMenu}
              open={Boolean(courseMenu)}
              onClose={() => setCourseMenu(null)}
              keepMounted
            >
              <MenuItem>
                {COURSE_TYPE_NAMES_MAPPING[COURSE_TYPE.DATA_SCIENCE]}
              </MenuItem>
            </Menu> */}
          </div>

          {/* ----Menu Items Starts ------ */}
          <div className='flex'>
            <nav className='hidden md:flex items-center gap-8'>
              <div>
                <CoachFreshDeskWidget />
              </div>
              <NavLink
                to={PATH.DASHBOARD}
                className='text-black font-medium'
                activeClassName='text-gray-400'
              >
                My Dashboard
              </NavLink>
            </nav>

            <span className='mx-2 md:mx-6 w-px hidden md:block bg-gray-400' />

            <div className='flex items-center gap-2 px-4'>
              {user ? (
                <>
                  <div className='ring-2 ring-ab-secondary rounded-full p-0.5'>
                    <RenderThumbnail
                      thumbnail={user.thumbnail}
                      name={user.firstName}
                      size={window.screen.width < 600 ? '2' : '2.75'}
                    />
                  </div>
                  {/* --- Mobile View --- */}
                  <div className='text-base md:whitespace-nowrap md:hidden truncate'>
                    Hi, {user?.firstName || 'Invalid Name'}
                  </div>

                  {/* --- Desktop View --- */}
                  <div
                    onClick={handleClick}
                    className='text-xs md:text-base font-semibold cursor-pointer md:whitespace-nowrap hidden md:block ml-1'
                  >
                    Hi, {user?.firstName || 'Invalid Name'}
                    <ExpandMoreIcon
                    // className='cursor-pointer'
                    // aria-controls='menu-list-grow'
                    // aria-haspopup='true'
                    // onClick={handleClick}
                    />
                  </div>
                </>
              ) : (
                <div className='flex items-center text-xs md:text-base font-semibold'>
                  <Link to='/login'>
                    <button className='px-2 py-2 md:px-5 md:py-2 mr-2 md:mr-3'>Login</button>
                  </Link>
                  <Link to='/login'>
                    <button className='px-2 py-2 md:px-5 md:py-2 bg-green-c1 text-white rounded'>
                      Create Free Account
                    </button>
                  </Link>
                </div>
              )}
              <Menu
                style={{ marginTop: '40px' }}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {/* <span className='w-full flex items-center gap-6 whitespace-nowrap px-4'>
                  Toggle Theme
                  <span className='w-full flex items-center justify-center'>
                    <NightsStayIcon
                      style={{
                        color:
                          themeSwitch === 'dark'
                            ? '#1609b0'
                            : 'rgba(0,0,0,0.4)',
                      }}
                    />
                    <Switch
                      checked={themeSwitch === 'light'}
                      color='default'
                      onChange={handleThemeSwitch}
                      inputProps={{ 'aria-label': 'switch for theme' }}
                    />
                    <WbSunnyIcon
                      style={{
                        color:
                          themeSwitch === 'light'
                            ? '#fec509'
                            : 'rgba(0,0,0,0.4)',
                      }}
                    />
                  </span>
                </span> */}
                <Link to={PATH.EDIT_PROFILE}>
                  <MenuItem onClick={handleClose}>Edit Profile</MenuItem>
                </Link>
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </div>
            {/* --- Hamburger --- */}
            <div className='md:hidden flex items-center'>
              <button
                className='outline-none'
                onClick={() => setMobileMenu((prevState) => !prevState)}
              >
                <MenuIcon />
              </button>
            </div>
          </div>
          {/* --- MENU ITEMS ENDS --- */}
        </div>
      </div>
      {menubar && (
        <div
          className='w-full flex items-center gap-4 mx-0 md:mx-10 justify-center md:justify-start'
          id='nav-menubar'
        >
          <IconButton onClick={history.goBack} size='small'>
            <ArrowBack />
          </IconButton>
          {menubar}
        </div>
      )}
      <div className='w-full flex items-center gap-12 mx-0 md:mx-10 justify-center md:justify-start'>
        {navBarLinks?.map((link, index) => (
          <NavLink
            key={index}
            className='text-black text-opacity-50 py-2 border-b-4 border-solid border-transparent rounded'
            activeClassName='font-medium border-ab-secondary text-ab-secondary text-opacity-100'
            to={link.to}
          >
            {link.name}
          </NavLink>
        ))}
      </div>
      {/* --- Mobile Menu --- */}
      <MobileNavBar
        logout={logout}
        setMobileMenu={setMobileMenu}
        mobileMenu={mobileMenu}
        themeSwitch={themeSwitch}
        handleThemeSwitch={handleThemeSwitch}
      />
    </>
  );
}

const MobileNavBar = ({ logout, setMobileMenu, mobileMenu, themeSwitch, handleThemeSwitch }) => {
  return (
    <div
      className={`absolute top-0 left-0 w-full h-full z-30 bg-ab-tertiary dark:bg-ab-primary-shade-1 transform transition-transform duration-500 ease-in-out ${
        mobileMenu ? '-translate-x-0' : 'translate-x-full'
      }`}
    >
      <div className='w-full h-auto py-4'>
        <div className='flex justify-between items-center min-w-min px-6 my-1'>
          <div>
            <a href='https://www.almabetter.com'>
              <img className='object-cover h-5' src={almabetterLogo} alt='Almabetter Logo' />
            </a>
          </div>
          <div className='flex'>
            <div className='md:hidden flex items-center pl-5'>
              <button
                className='outline-none'
                onClick={() => setMobileMenu((prevState) => !prevState)}
              >
                <CloseIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
      <ul className='border-t border-b border-solid border-ab-primary dark:border-ab-tertiary border-opacity-30 dark:border-opacity-30 divide-y divide-ab-primary dark:divide-ab-tertiary divide-opacity-30 dark:divide-opacity-30 divide-solid mb-8 '>
        <li>
          {/* <div className='flex items-center gap-3 p-4'>
            <BrightnessMedium />
            <span>Toggle Theme</span>
            <span className='flex items-center justify-center ml-3'>
              <NightsStayIcon
                style={{
                  color: themeSwitch === 'dark' ? '#1609b0' : 'rgba(0,0,0,0.5)',
                }}
              />
              <Switch
                checked={themeSwitch === 'light'}
                color='default'
                onChange={handleThemeSwitch}
                inputProps={{ 'aria-label': 'switch for theme' }}
              />
              <WbSunnyIcon
                style={{
                  color:
                    themeSwitch === 'light'
                      ? '#fec509'
                      : 'rgba(255,255,255,0.5)',
                }}
              />
            </span>
          </div> */}
        </li>
        <li>
          <Link
            to='/edit-profile'
            onClick={() => setMobileMenu(false)}
            className='flex items-center text-sm px-4 py-4'
          >
            <Person iconStyle='w-6 mr-3 inline text-ab-primary dark:text-ab-tertiary' />
            Edit Profile
          </Link>
        </li>
        <li>
          <Link
            to='/program-information'
            onClick={() => setMobileMenu(false)}
            className='flex text-sm px-4 py-4'
          >
            <Card iconStyle='w-6 mr-3 inline' />
            Program Information
          </Link>
        </li>
        <li>
          <span onClick={logout} className='flex text-sm px-4 py-4'>
            <LogoutDoor iconStyle='w-6 mr-3 inline' />
            Sign Out
          </span>
        </li>
      </ul>

      <CommonButton
        className='rounded text-white text-base p-3 m-auto w-10/12 mb-2 block'
        style={{ backgroundColor: '#cc0000' }}
        onClick={() => {
          window.location.href = THINKIFIC_CONSTANTS.LINK_TO_DASHBOARD;
        }}
      >
        My Dashboard
      </CommonButton>
    </div>
  );
};
